import Card from 'react-bootstrap/Card'
import FormControl from 'react-bootstrap/FormControl'
import Button from 'react-bootstrap/Button'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { FaTrash } from 'react-icons/fa'

function Element(props) {
  const del = props.fns[0];
  const sett = props.fns[1];

  function onPointsChange(event) {
    const num = Number(event.target.value);
    if (!isNaN(num)) {
      if ((num >= 0) && (num <= 100)) {
        sett({ ...props.state, points: num });
      }
    }
  }

  return (
    <Card style={{backgroundColor:'#DFEFFF'}}>
      <Card.Body style={{paddingTop: 7, paddingBottom: 7}}>
        <Row className='align-items-center'>
          <Col style={{ paddingLeft: 2, paddingRight: 2 }}>
            <FormControl
              as="textarea"
              value={props.state.text}
              onChange={(event => sett({ ...props.state, text: event.target.value }))}
            />
          </Col>
          <Col sm={2} lg={2} style={{ paddingLeft: 2, paddingRight: 2 }} >
            <FormControl
              className="text-right"
              value={props.state.points}
              onChange={onPointsChange}
            />
          </Col>
          <Col lg="auto" style={{ paddingTop: 2, paddingLeft: 2, paddingRight: 2 }}>
            <Button onClick={del} variant='link'>
              <FaTrash />
            </Button>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  )
}

export default Element