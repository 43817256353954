import React from 'react'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Element from './Element'
import { FaPlus } from 'react-icons/fa'
import { OverlayTrigger } from 'react-bootstrap/esm'
import Tooltip from 'react-bootstrap/Tooltip'


function Category(props) {
  const state = props.state;
  const add = props.fns[0];
  const del = props.fns[1];
  const sett = props.fns[2];
  const tip = props.tip;
  const kind = props.kind;

  function elemSetState(i) {
    const delOne = () => del(i);
    const setOne = (s) => sett(i, s);
    return [delOne, setOne];
  }

  function onAdd() {
    add({ text: 'New '+kind, points: 5 });
  }

  const renderCatTip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      {tip}
    </Tooltip>
  );

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Max 6 competencies per category
    </Tooltip>
  );

  return (
    <Card style={{ backgroundColor: '#E8E8E8' }}>
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={renderCatTip}
      >
        <Card.Header style={{ color: 'white', backgroundColor: '#3F5F7F' }}>
          <b>{props.title}</b>
        </Card.Header>
      </OverlayTrigger>
      <Card.Body style={{ paddingLeft: 10, paddingRight: 10, paddingTop: 10, paddingBottom: 10 }}>
        {state.map((s, i) => <Element key={i} state={s} fns={elemSetState(i)} />)}
        <Card style={{ border: 'none', backgroundColor: '#E8E8E8', paddingTop: 5 }}>
          <OverlayTrigger
            placement="bottom"
            delay={{ show: 1000, hide: 400 }}
            overlay={renderTooltip}
          >
            <Button onClick={onAdd} disabled={state.length > 5}><FaPlus /></Button>
          </OverlayTrigger>
          <Card>
            <Card.Header className='align-items-center' style={{ backgroundColor: '#DFEFFF' }}>
              {state.reduce((acc, e) => acc + e.points, 0)} points
            </Card.Header>
          </Card>
        </Card>
      </Card.Body>
    </Card >
  )
}

export default Category;