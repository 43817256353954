import React, { useState, useRef, useEffect } from 'react'
import produce from 'immer'

import { FirebaseAuthProvider, FirebaseAuthConsumer } from "@react-firebase/auth"
import firebase from 'firebase/app'
import 'firebase/firestore'
import "firebase/auth"

import Admin from './components/Admin'
import Topbar from './components/Topbar'
import Job from './components/Job'
import Emailme from './components/Emailme'
import Confirm from './components/Confirm'
import { uid } from 'uid';

import { BrowserRouter, Route, Switch } from 'react-router-dom';
import ERlogo from './essilen_logo_white_small.png';
import CHlogo from './chwide.png';

import './App.css'

const userStateType = { NOUSER: 'no user', LOADING: 'loading', READCOMPLETE: 'read complete', LOADED: 'loaded' };

/*************************************************/


/*************************************************/
async function readFromDb(firebase, user, userState) {
  var db = firebase.firestore();

  if (!user || !firebase || !user.uid) {
    alert("ERROR: Invalid read request")
    return;
  }
  console.log("Attempting read");
  const jobRef = db.collection('users').doc(user.uid).collection('jobs');
  const jobs = await jobRef.get();

  userState.current = userStateType.READCOMPLETE;

  if (!jobs.empty) {
    var newState = [];
    jobs.forEach(
      job => {
        var data = job.data();
        var categories = [];

        //Order is important! Firestore does not support nested arrays so has to be done this way.
        categories.push(data.skills);
        categories.push(data.abilities);
        categories.push(data.preferences);

        newState.push({
          jobUid: data.jobUid,
          categories: categories,
          jobName: data.jobName
        });
      }
    );
    return newState;
  }
  else {
    return null;
  }
}

async function deleteFromDb(firebase, user, job) {
  var db = firebase.firestore();

  if (!user || !user.uid || !job) {
    alert("invalid call to deleteFromDb");
    return;
  }
  console.log("Deleting job ", user.uid, job.jobUid);
  const result = await db.collection('users').doc(user.uid).collection('jobs').doc(job.jobUid).delete();
  console.log("Result: ", result);
  console.log("DB deletion completed of jobUid: ", job.jobUid);
}

function App() {
  async function writeToDb(currentUser, job) {
    if(!currentUser.current) {
      console.log("Not signed in, no write");
      return;
    }
    var userPayload = {
      userName: currentUser.current.displayName,
      email: currentUser.current.email,
      lastSeen: Date.now()
    };
  
    var jobPayload = {
      skills: job.categories[0],
      abilities: job.categories[1],
      preferences: job.categories[2],
      jobUid: job.jobUid,
      jobName: job.jobName,
      lastTouch: firebase.firestore.Timestamp.now()
    }
  
    var db = firebase.firestore();
    console.log("requesting save of: ", job);
    await db.collection("users").doc(currentUser.current.uid).set(userPayload).catch(e => console.log(e));
    await db.collection("users").doc(currentUser.current.uid).collection("jobs").doc(job.jobUid).set(jobPayload).catch(e => console.log(e));
    console.log("save complete for job ", job);
  }

  function newEmptyJob() {
    return { jobUid: uid(32), categories: [[], [], []], jobName: '' }
  }

  function isEmptyJob(job) {
    return ((job.categories.reduce((acc, arr) => arr.length === 0 ? acc : false, true)) && (job.jobName.length === 0))
  }

  const [jobIdx, setJobIdx] = useState(0);
  const [state, setState] = useState([newEmptyJob()]);

  function setStateJob(i) {
    return function (f) {
      setState(produce(draft => { draft[i] = f(draft[i]) }))
    }
  }

  var currentUser = useRef(null);
  var userState = useRef(userStateType.NOUSER);
  var lastWrite = useRef(0);

  const firebaseConfig = {
    apiKey: "AIzaSyAMTkRHVMqnT3BUt2uB4jAVsJy7yCIbUX4",
    authDomain: "sap-framework-283c1.firebaseapp.com",
    projectId: "sap-framework-283c1",
    storageBucket: "sap-framework-283c1.appspot.com",
    messagingSenderId: "728451784470",
    appId: "1:728451784470:web:903f9cab3e5e9c98c9fa37",
    measurementId: "G-MNTQR43GZN"
  };

  function writeStaleData(user, state) {
    if (Date.now() - lastWrite.current > 3000) {
      //write
      writeToDb(user, state).then(
        lastWrite.current = Date.now()
      );
      //update lastWrite
    }
    else {
      console.log("Deferring write");
    }
  }

  useEffect(() => {
    console.log(state);
    if (userState.current === userStateType.READCOMPLETE) {
      console.log("Load user fully completed.")
      userState.current = userStateType.LOADED;
    } else if (userState.current === userStateType.LOADED) {
      //PJOTODO: add timer logic. Be careful, though, timer logic could break in LOG-OUT scenario.
      writeStaleData(currentUser, state[jobIdx]); //note this will execute asynchronously
    } else {
      console.log(`Not writing to DB because user state is => ${userState.current}`);
    }
  }, [state, jobIdx]);

  /* This function is trying to manage two transitions:
    1: New user logs in. This involves reading the DB for any jobs and setting state accordingly.
    2: User logs out. Reset state to zero.
    */

  function manageUserDeltas(user) {
    console.log(`New render. User state: ${userState.current}`, state, user);
    if (user) {
      if ((currentUser.current == null) || (user.uid !== currentUser.current.uid)) {
        // This is the "new user has logged in" state transition
        userState.current = userStateType.LOADING;
        currentUser.current = user;

        readFromDb(firebase, user, userState).then(newState => {
          if (newState) {
            console.log("Found records for user, updating state.");
            state.forEach(job => {
              if (!isEmptyJob(job)) {
                writeToDb(currentUser, job);
                newState.push(job);
              }
            });
            setState(newState);
            setJobIdx(newState.length - 1);
          } else {
            console.log("No records found for user, creating blank slate.");
            state.forEach(job => {
              if (!isEmptyJob(job)) {
                writeToDb(currentUser, job);
              }
            });
          }
        });
      }
    } else {
      if (currentUser.current != null) {
        // This is the LOGOUT transition
        console.log("Detected user log out, resetting state.");
        setState([newEmptyJob()]);
        setJobIdx(0);
      }
      currentUser.current = null;
      userState.current = userStateType.NOUSER;
    }
  }

  const allJobs = state.map((job, i) => [job.jobName, function () { writeToDb(currentUser, state[jobIdx]).then(()=>setJobIdx(i)) }]);

  const [showModal, setShowModal] = useState(false);
  function onDeleteJob() {
    setShowModal(true);
  }

  function handleDelete() {
    console.log("handle delete called, ", jobIdx);

    deleteFromDb(firebase, currentUser.current, state[jobIdx]);

    if (state.length === 1) {
      setState([newEmptyJob()]);
      setJobIdx(0);
    } else {
      setState(produce(draft => { draft.splice(jobIdx, 1) }));
      if (jobIdx > 0) {
        setJobIdx(jobIdx - 1)
      }
    }
    setShowModal(false);
  }

  function onDuplicateJob() {
    var newJob = JSON.parse(JSON.stringify(state[jobIdx]));
    newJob.jobName = newJob.jobName + ' (2)';
    newJob.jobUid = uid(32);
    const nJobs = state.length;

    writeToDb(currentUser, state[jobIdx]);
    setState(produce(draft => { draft.push(newJob) }));
    setJobIdx(nJobs);
  }

  function newJob() {
    const nJobs = state.length;
    writeToDb(currentUser, state[jobIdx]);
    setState(produce(draft => { draft.push(newEmptyJob()) }));
    setJobIdx(nJobs);
  }

  function logout() {
    // Important to flush all data before logging out.
    writeToDb(currentUser, state[jobIdx]).catch(e => console.log(e)).then(() => {
      firebase.app().auth().signOut()
    }
    );
  }
  var hostURL = window.location.href;
  var logo = ERlogo;
  var copyright = '2021 Essilen Research';
  var privacyURL = 'https://app.termly.io/document/privacy-policy/6ccb1fc1-f401-4653-b473-a3231917bba8';
  

  if(hostURL.includes('certifiedhiring')) {
    logo = CHlogo;
    copyright = '2021 Certified Hiring'
    privacyURL = 'https://www.certifiedhiring.com/privacy';
  }
  return (
    <FirebaseAuthProvider {...firebaseConfig} firebase={firebase}>
      <FirebaseAuthConsumer>
        {
          ({ isSignedIn, firebase, user }) => {
            // Note: may have side-effect of changing state.
            // In a future version we should render differently if this 
            // function is loading a new user...
            manageUserDeltas(user);
            return (
              <>
                <BrowserRouter>
                  <Switch>
                    <Route path='/admin'>
                      <Admin user={user} />
                    </Route>
                    <Route path='/'>
                      <Confirm show={showModal} handleClose={() => setShowModal(false)} handleDelete={handleDelete} />
                      <Topbar jobs={allJobs} isSignedIn={isSignedIn} logo={logo} user={user} newJob={newJob} onLogout={logout} />
                      <Job state={state[jobIdx]} setState={setStateJob(jobIdx)} onDelete={onDeleteJob} onDuplicate={onDuplicateJob} />
                      <Emailme state={state[jobIdx]} isSignedIn={isSignedIn} user={user} />
                      &copy; {copyright}&nbsp;-&nbsp;
                      <a href={privacyURL}>Privacy Policy</a>
                    </Route>
                  </Switch>
                </BrowserRouter>
              </>
            );
          }
        }
      </FirebaseAuthConsumer>
    </FirebaseAuthProvider>
  );
}

export default App;