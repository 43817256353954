import React from 'react'
import ReactDOMServer from 'react-dom/server';
import Container from 'react-bootstrap/Container'
import Col from 'react-bootstrap/Col'
import Button from 'react-bootstrap/Button'
import { Card } from 'react-bootstrap'

function Emailme(props) {
  const state = props.state
  const user = props.user

  function sendEmail() {
    function generate(i) {
      return ReactDOMServer.renderToStaticMarkup(
        <table>
          <tr>
            <th>Competency</th>
            <th>Points</th>
          </tr>
          {state.categories[i].map((e) =>
            <tr>
              <td>{e.text}</td>
              <td>{e.points}</td>
            </tr>
          )}
        </table>
      )
    }

    window.emailjs.send("gmail", "template_k8hstdb", {
      to_email: user.email,
      to_name: user.displayName,
      from_name: "Essilen Research",
      reply_to: "info@essilen-research.com",

      job_title: state.jobName, skills: generate(0), abilities: generate(1), preferences: generate(2)
    }).then((result) => {
      window.alert(`Thank you, an email has been sent to ${user.email}`);
      console.log(result.text);
    }, (error) => {
      console.log(error.text);
    });
  }

  return (
    <Container style={{ paddingTop: 20 }}>
      <Col lg={{ span: 8, offset: 2 }}>
        <Card className='text-center' style={{ backgroundColor: '#E8E8E8' }}>
          <Card.Header style={{ color: 'white', backgroundColor: '#3F5F7F' }}>
            Want to receive your completed worksheet?
          </Card.Header>
          <Card.Body>
            {props.isSignedIn
              ?
              <>
                <Button variant="primary" onClick={sendEmail}>
                  Email Me
                </Button>
                <p><i>We'll never share your email with anyone else.</i></p>
              </>
              :
              <p><i>Please sign in to be able to receive your SAP worksheet in your inbox.</i></p>
            }
          </Card.Body>
        </Card>
      </Col>
    </Container>
  )
}

export default Emailme;