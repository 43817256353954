import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Card from 'react-bootstrap/Card'

function Totalpoints(props) {

  function getStyle() {
    var bgCol;
    if (props.points > 100) {
      bgCol = '#FF3F3F'
    } else {
      if (props.points === 100) {
        bgCol = '#3FAF3F'
      } else {
        bgCol = '#047Bff'
      }
    }
    return { color: '#FFFFFF', backgroundColor: bgCol }
  }
  return (
    <Container fluid style={{ paddingTop: 20 }}>
      <Row>
        <Col>
          <Card>
            <Card.Header style={getStyle()}>
              <h4>{props.points} total points</h4>
          </Card.Header>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default Totalpoints;