import React from 'react'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import Button from 'react-bootstrap/Button'
import firebase from 'firebase/app'

function Topbar(props) {
  function rightSide() {
    return props.isSignedIn
      ?
      <Navbar.Text>
        <b>{props.user.displayName}</b>
        <Button
          variant='info'
          style={{ marginLeft: 10 }}
          onClick={() => { props.onLogout(); }}
        >
          Logout
        </Button>
      </Navbar.Text>
      :
      <Button
        variant='success'
        onClick={() => {
          const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
          firebase.auth().signInWithPopup(googleAuthProvider);
        }}
      >
        Login
      </Button>
  }

  function getJobs() {
    if (props.jobs.length === 0) {
      return <NavDropdown.Item>none</NavDropdown.Item>
    } else {
      return props.jobs.map((pair, i) =>
        <NavDropdown.Item key={pair[0]} onSelect={pair[1]}>
          {pair[0].length === 0 ? (<i>&lt;blank&gt;</i>) : pair[0]}
        </NavDropdown.Item>
      )
    }
  }

  return (
    <Navbar bg="dark" variant="dark">
      <Navbar.Brand href="">
        <img
          alt=""
          src={props.logo}
          height="51"
          className="d-inline-block align-top"
        />{' '}
    </Navbar.Brand>
      <Navbar.Collapse id="responsive-navbar-nav" className="justify-content-end">
        <Nav className="mr-auto" onSelect={props.newJob}>
          <Nav.Link eventKey="newjob">New Job</Nav.Link>
          <NavDropdown title="My Jobs" id="collasible-nav-dropdown">
            {getJobs()}
          </NavDropdown>
        </Nav>
        {rightSide()}
      </Navbar.Collapse>
    </Navbar>
  )
}

export default Topbar;