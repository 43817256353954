import React from 'react'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import FormControl from 'react-bootstrap/FormControl'
import { OverlayTrigger } from 'react-bootstrap/esm'
import Tooltip from 'react-bootstrap/Tooltip'
import Button from 'react-bootstrap/Button'
import { FaCopy, FaTrash } from 'react-icons/fa'

import Category from './Category'
import Totalpoints from './Totalpoints'

import produce from 'immer';
import { Card } from 'react-bootstrap'

function Job(props) {
  const state = props.state;
  const setState = props.setState;
  console.log(state);
  const job = props.state.jobName;
  function setJob(name) {
    props.setState(produce(draft => { draft.jobName = name }));
  }

  function catSetState(i) {
    const add = function (s) {
      return setState(produce(draft => { draft.categories[i].push(s) }));
    };
    const del = function (j) {
      return setState(produce(draft => { draft.categories[i].splice(j, 1) }));
    }
    const sett = function (j, s) {
      return setState(produce(draft => { draft.categories[i][j] = s }));
    }
    return [add, del, sett];
  }

  const skillTip = 'Skills are specific things a person can do. Like write Javascript or fly a plane.';
  const abilityTip = 'Abilities are about general thinking. Are they smart, a good debugger, a good leader?';
  const prefTip = 'Preferences are about their desires. Do they lead or follow? Do they prefer working alone or in teams?'

  const renderJobTip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Make sure to use clear job titles. "Senior Full Stack Engineer" is a lot better than "Django Ninja".
    </Tooltip>
  );

  return (
    <div className="App">
      <Container style={{ paddingTop: 10 }}>
        <Row className='align-items-center'>
          <Col>
            <Card style={{ backgroundColor: '#DFEFFF' }}>
              <Card.Header style={{ color: 'white', backgroundColor: '#3F5F7F' }}>
                <h2>SAP Framework Creator</h2>
              </Card.Header>
              <Col lg={{ span: 8, offset: 2 }} >
                <Card.Body>
                  Create your job description by describing the competencies needed.
                  Assign point values, but don't go over 100!
              </Card.Body>
              </Col>

            </Card>
          </Col>
        </Row>
      </Container>
      <Container fluid style={{ paddingLeft: 0, paddingRight: 0, paddingTop: 10 }}>
        <Col lg={{ span: 4, offset: 4 }}>
          <Card style={{ backgroundColor: '#E8E8E8' }}>
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={renderJobTip}
            >
              <Card.Header>
                <Row>
                  <Col style={{paddingLeft:0, paddingRight:0, paddingTop:5}} xs={{ span:5, offset:3  }}><h4>Job Title</h4></Col>
                  <Col style={{paddingLeft:0, paddingRight:0}} xs={2}><Button onClick={props.onDuplicate}><FaCopy /></Button></Col>
                  <Col style={{paddingLeft:0, paddingRight:0}} xs={2}><Button onClick={props.onDelete}><FaTrash /></Button></Col>
                </Row>
              </Card.Header>
            </OverlayTrigger>
            <FormControl
              value={job}
              onChange={(event => setJob(event.target.value))}
            />
          </Card>
        </Col>
      </Container>
      <Container fluid style={{ paddingLeft: 20, paddingRight: 20 }}>
        <Row style={{ paddingTop: 20 }}>
          <Col style={{ paddingLeft: 2, paddingRight: 2, paddingBottom: 10 }} xs={12} lg={4}>
            <Category title='Skills' kind='skill' state={state.categories[0]} fns={catSetState(0)} tip={skillTip} />
          </Col>
          <Col style={{ paddingLeft: 2, paddingRight: 2, paddingBottom: 10 }} xs={12} lg={4}>
            <Category title='Abilities' kind='ability' state={state.categories[1]} fns={catSetState(1)} tip={abilityTip} />
          </Col>
          <Col style={{ paddingLeft: 2, paddingRight: 2, paddingBottom: 10 }} xs={12} lg={4}>
            <Category title='Preferences' kind='preference' state={state.categories[2]} fns={catSetState(2)} tip={prefTip} />
          </Col>
        </Row>
      </Container>
      <Totalpoints points={state.categories.reduce((acc, elem) => elem.reduce((acc, e) => acc + e.points, acc), 0)} />
    </div >
  );
}

export default Job;
