import React, { useState, useEffect } from 'react'
import firebase from 'firebase/app'
import BootstrapTable from 'react-bootstrap-table-next'
import Job from './Job'
import ToolkitProvider from 'react-bootstrap-table2-toolkit';

async function checkIfAdmin(user) {
  var db = firebase.firestore();

  if (!user || !db) {
    return;
  }
  var adminRef = db.collection('admins').doc(user.uid);
  const admin = await adminRef.get();

  return (admin.exists);
}

async function readAllJobs(userArray) {
  var db = firebase.firestore();
  var jobs = [];

  //const ref = db.collection('users').doc("S7HLDGMvPDXG7u5xcu7BVxfy6973").collection("jobs").doc("f068fb4b2188e75d2cadfd4e92fa39f1");
  const allJobs = await db.collectionGroup('jobs').get();

  allJobs.forEach((job) => {
    var dateString = '';
    if (job.data().lastTouch) {
      dateString = job.data().lastTouch.toDate().toString();
    }

    jobs.push({
      ...job.data(),
      date: dateString,
      username: (userArray.find(u => u.uid === job.ref.path.split('/')[1])).userName,
      email: (userArray.find(u => u.uid === job.ref.path.split('/')[1])).email
    });
  });
  return jobs;
}

async function readAllUsers() {
  var db = firebase.firestore();
  var users = [];

  const allUsers = await db.collection('users').get();

  allUsers.forEach((user) => {
    users.push({ ...user.data(), uid: user.ref.path.split('/')[1] });
  });
  return users;
}

function ROJob(p) {
  var categories = [];
  categories.push(p.jobData.skills);
  categories.push(p.jobData.abilities);
  categories.push(p.jobData.preferences);

  // Used for read-only Job display
  function blankSetState(i) {
    return function (f) {
      return;
    }
  }
  const jobState = {
    jobUid: p.jobData.jobUid,
    categories: categories,
    jobName: p.jobData.jobName
  }
  return (
    <Job state={jobState} setState={blankSetState} onDelete={() => void 0} onDuplicate={() => void 0} />
  );
}

function Admin(props) {
  const [adminState, setAdminState] = useState(false);
  const [users, setUsers] = useState([]);
  const [jobs, setJobs] = useState([]);
  var nonZeroJobs = false;
  var columns = [];

  //The idea is to run this ONLY when adminState changes. Avoid multiple DB reads.
  useEffect(() => {
    if (adminState) {
      readAllUsers().then(uArray => {
        readAllJobs(uArray).then(jobArray => {
          setJobs(jobArray);
          setUsers(uArray);
        });
      });
    }
  }, [adminState]);

  checkIfAdmin(props.user).then((isAdmin) => setAdminState(isAdmin));
  console.log(users, jobs);

  if (jobs.length > 0) {
    function emailFormat(cell) {
      return (<a href="mailto:{cell}">{cell}</a>);
    }

    function dateFormat(cell) {
      return ((new Date(cell)).toDateString());
    }

    function dateSort(a, b, order, dataField, rowA, rowB) {
      let dateA = new Date(a);
      let dateB = new Date(b);


      if (order === 'asc') {
        return (dateA > dateB ? 1 : -1);
      } else {
        return (dateA < dateB ? 1 : -1);
      }
    }

    nonZeroJobs = true;
    columns = [
      { dataField: 'jobName', text: 'Job name' },
      { dataField: 'username', text: 'User name', sort: true },
      { dataField: 'email', text: 'email', formatter: emailFormat },
      { dataField: 'date', sortFunc: dateSort, text: 'Date', sortType: 'datetime', formatter: dateFormat, sort: true },
      { dataField: 'jobUid', hidden:true }
    ]
  }

  if (adminState && nonZeroJobs) {
    const expandRow = {
      onlyOneExpanding: true,
      renderer: (row) => {
        return (<ROJob jobData={row} />);
      },
      showExpandColumn: true
    };
    return (
      <>
        <h1>User Activity 🧑‍💻 </h1>
        <ToolkitProvider keyField='jobUid' data={jobs} columns={columns} >
          {props=>
            <BootstrapTable {...props.baseProps} expandRow={expandRow} />
          }
        </ToolkitProvider >
      </>);
  }
  else if (adminState && !nonZeroJobs) {
    return (<h1>No jobs to show</h1>)
  } else {
    return (<h1>You are not authorized.</h1>);
  }
}

export default Admin;